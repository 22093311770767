import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const useIdle = (delay, tabSyncTime) => {
  const [isIdle, setIsIdle] = useState(false);

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = () => {
    setIsIdle(false);
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: delay,
    crossTab: true,
    syncTimers: tabSyncTime,
    throttle: 500,
  });

  return isIdle;
};

export default useIdle;
