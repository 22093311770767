import React from 'react';
import {
  Routes as ViewsRoutes, BrowserRouter, Route, Navigate,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Main as MainLayout } from '../layouts';
import ProtectedOutlet from './ProtectedOutlet';
import PageContextOutlet from './PageContextOutlet';
import { UserAuthContextProvider } from '../context/UserAuthContext';
import CircularLoading from '../components/atoms/Loader/CircularLoading';

const LazySignup = React.lazy(() => import('../views/Auth'));
const LazyLogin = React.lazy(() => import('../views/Auth/components/Login'));
const LazyLoginEmailPwd = React.lazy(() => import('../views/Auth/components/LoginEmailPwd'));
const LazyForgotPassword = React.lazy(() => import('../views/Auth/components/ForgotPassword'));
// const LazyAllLoans = React.lazy(() => import('../views/AllLoans'));
const LazyLoanDetails = React.lazy(() => import('../views/LoanDetails'));
const LazyCompliancePage = React.lazy(() => import('../views/CompliancePage'));
const LazyExtractionDetails = React.lazy(() => import('../views/ExtractionScreen/ExtractionScreen'));
const LazyDashboardDetails = React.lazy(() => import('../views/Dashboard/Dashboard'));
const LazyUserManagement = React.lazy(() => import('../views/UserManagement/UserManagement'));
const LazyComplianceRulePreset = React.lazy(() => import('../views/CompliancePresetPage/ComplianceRulePreset'));
const LazyLoginUpload = React.lazy(() => import('../views/UploadPortal/Login/Login'));
const LazyUploadPortal = React.lazy(() => import('../views/UploadPortal/UploadScreen/UploadPortal'));
const LazyUploadDocuments = React.lazy(() => import('../views/UploadPortal/UploadScreen/components/UploadDocuments'));
const LazyDocumentReview = React.lazy(() => import('../views/DocumentReview'));
const LazyPendingReview = React.lazy(() => import('../views/PendingReview'));
const LazyOpsDashboard = React.lazy(() => import('../views/OpsDashboard/OpsDashboard'));
const LazyApplicationSummary = React.lazy(() => import('../views/ApplicationSummary/ApplicationSummary'));
const LazySplitDocumentReview = React.lazy(() => import('../views/SplitDocumentReview/SplitDocumentReview'));
const LazyAllLoansRevamp = React.lazy(() => import('../views/AllLoansRevamp/AllLoans'));

export const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    height: '90vh',
    justifyContent: 'center',
  },
}));

function Routes() {
  const Classes = useStyles();
  return (
    <BrowserRouter>
      <UserAuthContextProvider>
        <ViewsRoutes>
          {/* Public Routes */}
          <Route path="*" element={<Navigate to="/dashboard" />} />
          {/* <Route
            path="error"
            element={<Error />}
          />
          <Route
            path="unauthorized"
            element={<Unauthorized />}
          /> */}

          <Route
            path="/signup"
            element={(
              <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                <LazySignup />
              </React.Suspense>
            )}
          />
          <Route
            path="/login"
            element={(
              <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                <LazyLogin />
              </React.Suspense>
            )}
          />
          {process.env.REACT_APP_LOGIN_PWD_ENABLE === 'true' && (
          <Route
            path="/login-admin"
            element={(
              <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                <LazyLoginEmailPwd />
              </React.Suspense>
            )}
          />
          )}
          <Route
            path="/upload-portal/login"
            element={(
              <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                <LazyLoginUpload />
              </React.Suspense>
            )}
          />
          <Route
            path="/upload-portal/:id"
            element={(
              <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                <LazyUploadPortal />
              </React.Suspense>
            )}
          />
          <Route
            path="/upload-documents"
            element={(
              <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                <LazyUploadDocuments />
              </React.Suspense>
            )}
          />
          <Route
            path="/forgot-password"
            element={(
              <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                <LazyForgotPassword />
              </React.Suspense>
          )}
          />

          {/* Protected Routes */}
          <Route element={<ProtectedOutlet />}>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Navigate replace to="/dashboard" />} />
              <Route
                path="/all-loans"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyAllLoansRevamp />
                  </React.Suspense>
                )}
              />
              <Route
                path="/all-loans-new"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyAllLoansRevamp />
                  </React.Suspense>
                )}
              />
              <Route
                path="/document-review"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyDocumentReview />
                  </React.Suspense>
                )}
              />
              <Route
                path="/split-document-review"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazySplitDocumentReview />
                  </React.Suspense>
                )}
              />
              <Route
                path="/ops-dashboard"
                element={(
                  <React.Suspense fallback={<>...</>}>
                    <LazyOpsDashboard />
                  </React.Suspense>
                )}
              />
              <Route
                path="/existing-loan-changes"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyPendingReview />
                  </React.Suspense>
                )}
              />

              {localStorage.getItem('userRole') !== 'Loan Administrator'
              && (
              <Route
                path="/compliance-rule-preset"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyComplianceRulePreset />
                  </React.Suspense>
          )}
              />
              )}
              <Route
                path="/admin-compliance-details"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyCompliancePage />
                  </React.Suspense>
                )}
              />
              <Route
                path="/all-loans/compliance-details"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyCompliancePage />
                  </React.Suspense>
                )}
              />
              <Route
                path="all-loans/loan-details"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyLoanDetails />
                  </React.Suspense>
                )}
              />
              <Route element={<PageContextOutlet />}>
                <Route
                  path="/:loanDetails"
                  element={(
                    <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                      <LazyLoanDetails />
                    </React.Suspense>
                )}
                />
                <Route
                  path="/compliance-details"
                  element={(
                    <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                      <LazyCompliancePage />
                    </React.Suspense>
                )}
                />
              </Route>
              <Route
                path="/extraction"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyExtractionDetails />
                  </React.Suspense>
                )}
              />
              <Route
                path="/all-loans/extraction"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyExtractionDetails />
                  </React.Suspense>
                )}
              />
              <Route
                path="/dashboard"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyDashboardDetails />
                  </React.Suspense>
                )}
              />
              <Route
                path="/all-loans/application-summary"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyApplicationSummary />
                  </React.Suspense>
                )}
              />
              <Route
                path="/application-summary"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyApplicationSummary />
                  </React.Suspense>
                )}
              />
              {localStorage.getItem('userRole') !== 'Loan Administrator'
              && (
              <Route
                path="/user-management"
                element={(
                  <React.Suspense fallback={<div className={Classes.loader}><CircularLoading color="0065FF" size="10vh" /></div>}>
                    <LazyUserManagement />
                  </React.Suspense>
                )}
              />
              )}
            </Route>
          </Route>
        </ViewsRoutes>
      </UserAuthContextProvider>
    </BrowserRouter>
  );
}
export default Routes;
