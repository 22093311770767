import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import theme from '../../../theme';
import WarningIcon from '../../../assets/warningIcon.svg';
import SuccessIcon from '../../../assets/successIcon.svg';
import ErrorIcon from '../../../assets/errorIcon.svg';

// for snackbar transition
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  () => ({
    '& .MuiSnackbar-root': {
      zIndex: 3,
      top: '2.4em',
    },
  }),
);

export default function Toaster(props) {
  const {
    open, status, msg, handleClose,
  } = props;
  const [show, setShow] = useState('');
  const [iconDisplay, setIconDisplay] = useState(false);
  const [showBorder, setShowBorder] = useState(theme.palette.blueCobaltMid);
  const handleIcon = (apiStatus) => {
    if (apiStatus === 'success') {
      setShow(SuccessIcon);
      setShowBorder(theme.palette.greenDefault);
      setIconDisplay(true);
    } else if (apiStatus === 'error') {
      setShow(ErrorIcon);
      setShowBorder(theme.palette.redDefault);
      setIconDisplay(true);
    } else if (apiStatus === 'warning') {
      setShow(WarningIcon);
      setShowBorder(theme.palette.yellowDefault);
      setIconDisplay(true);
    }
  };

  useEffect(() => {
    handleIcon(status);
  }, [status]);

  return (
    <div style={{ zIndex: 10000 }}>
      <StyledSnackbar
        sx={{ marginTop: '2rem !important' }}
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
      >
        <Alert
          icon={iconDisplay ? <img style={{ width: '1rem', height: '1rem' }} src={show} alt="Icon" /> : ' '}
          sx={{
            width: '20rem',
            height: '4.5rem',
            borderLeft: 6,
            borderColor: showBorder,
            color: theme.palette.grayDark2,
            boxShadow: 1,
            backgroundColor: theme.palette.white,
          }}
          action={
            <CloseIcon fontSize="small" color={theme.palette.grayMid2} onClick={handleClose} />
          }
          onClose={handleClose}
          variant="filled"
          severity="success"
        >
          {msg}
        </Alert>
      </StyledSnackbar>
    </div>
  );
}
