import React from 'react';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { useStyles } from '../Styles';
import { useUserAuth } from '../../../context/UserAuthContext';
import theme from '../../../theme';
import PopupUser from '../../../assets/avatar.svg';
import { authService } from '../../../constants/urls/endpoints';

const StyledPopover = styled(Popover)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '0rem 0.25rem 0.25rem 0rem',
    marginTop: '1.4rem',
  },
}));

export default function ProfileDropdownPopover(props) {
  const {
    // isOpen,
    anchorEl, setAnchorEl, setIsProfileOpen,
  } = props;
  const classes = useStyles();
  const axiosPrivate = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const open = Boolean(anchorEl);
  const { logOut } = useUserAuth();
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setIsProfileOpen(false);
  };

  React.useEffect(() => {
    if (localStorage.getItem('firstName') === null) {
      navigate('/login', { state: { from: location } });
    }
  });

  // for capturing audit log
  const handlePostLogout = (autoLogout) => {
    const payload = {
      session_id: localStorage.getItem('sessionId'),
      auto_logout: autoLogout,
    };

    axiosPrivate.post(authService.LOGOUT, payload)
      .then((response) => {
        if (response.data.status) {
          navigate('/login', { state: { from: location } });
          window.localStorage.clear();
          sessionStorage.clear();
        }
      })
      .catch(() => {
        // handle error here
      });
  };

  const handleLogout = (autoLogout) => {
    logOut();
    handlePostLogout(autoLogout);
  };

  // for pointer hover over the entry
  function handleMouseEnter(e) {
    e.target.style.background = theme.palette.blueLight3;
  }
  // for pointer leave the entry
  function handleMouseLeave(e) {
    e.target.style.background = theme.palette.white;
  }

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div>
        <div className={`${classes.profile}`} style={{ border: '1 px solid black' }}>
          <div>
            <img src={PopupUser} alt="userIcon" className={`${classes.profileImage}`} />
          </div>
          <div className={`${classes.profileDetails}`}>
            <div className={`${classes.profileName} ${'h3Regular'}`}>{`${localStorage.firstName} ${localStorage.lastName}`}</div>
            <div className={`${classes.profileEmail} ${'h4Regular'}`}>{`${localStorage.email && localStorage.email}`}</div>
          </div>
        </div>
        <div className={`${classes.logout} ${'h3Regular'}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleLogout(false)} aria-hidden="true">Logout</div>
      </div>
    </StyledPopover>
  );
}
