import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import DashboardIcon from '../../../assets/dashboard.svg';
import LoansIcon from '../../../assets/loansIcon.svg';
import SidebarLogo from '../../../assets/SidebarLogo.svg';
import DociphiLogoWhite from '../../../assets/DociphiLogoWhite.svg';
import { useStyles } from '../Styles';
import fileIcon from '../../../assets/Dashboard/fileIcon.svg';
import pendingReviewScreen from '../../../assets/pendingReviewScreen.svg';
import CustomizedTooltip from '../../../components/atoms/Tooltip/CustomizedTooltip';
import ProfileDropdownPopover from './ProfileDropdownPopover';
import notificationIcon from '../../../assets/notificationIcon.svg';
import NotificationPopover from '../../../components/atoms/NotificationPopover/NotificationPopover';
import ClosedMenu from '../../../assets/Sidebar/ClosedMenu.svg';
import OpenedMenu from '../../../assets/Sidebar/OpenedMenu.svg';
import SettingsIcon from '../../../assets/Sidebar/Settings.svg';
import UserGuide from '../../../assets/userGuide.svg';
import HelpPopover from './HelpPopover';
import SettingsPopover from './SettingsPopover';
import User from '../../../assets/avatarWhite.svg';
import GreenIcon from '../../../assets/Dashboard/greenDotIcon.svg';

export default function Sidebar(props) {
  const classes = useStyles();
  const { isOpen, setIsOpen, userRole } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isNotifOpen, setIsNotifOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // for help
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [anchorElHelp, setAnchorElHelp] = React.useState(null);
  // for Settings
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const [unresolvedNotifications, setUnresolvedNotifications] = React.useState(0);

  const toggle = () => setIsOpen(!isOpen);
  // sidebar item
  const sidebarTopItem = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <img src={DashboardIcon} alt="Dashboard" height="25rem" width="25rem" />,
    },
    {
      path: '/document-review',
      name: 'Document Review',
      icon: <img src={fileIcon} alt="DocumentReview" height="25rem" width="25rem" />,
    },
    {
      path: '/existing-loan-changes',
      name: 'Existing Loan Changes',
      icon: <img src={pendingReviewScreen} alt="PendingReview" height="25rem" width="25rem" />,
    },
    {
      path: '/all-loans',
      name: 'All Loans',
      icon: <img src={LoansIcon} alt="Loans" height="25rem" width="25rem" />,
    },
  ];

  // sidebar item for loan admin
  const sidebarTopItemLoanAdmin = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <img src={DashboardIcon} alt="Dashboard" />,
    },
    {
      path: '/all-loans',
      name: 'All Loans',
      icon: <img src={LoansIcon} alt="Loans" />,
    },
  ];

  useEffect(() => {
    if (localStorage.getItem('firstName') === null) {
      navigate('/login', { state: { from: location } });
    }
  });

  const isSettingsActive = () => {
    if (location?.pathname?.includes('/user-management') || location?.pathname?.includes('/compliance-rule-preset') || location?.pathname?.includes('/admin-compliance-details')) {
      return true;
    }
    return false;
  };

  const getUnresolvedNotificationsCount = () => {
    if (unresolvedNotifications === 0) return 0;
    if (unresolvedNotifications > 99) return '99+';
    return unresolvedNotifications;
  };

  return (
    <div style={{ width: isOpen ? '15rem' : '4rem', transition: isOpen ? 'ease-in 0.2s' : 'ease-in 0.2s', position: 'fixed' }} className={classes.sidebar}>
      <div style={{ marginRight: isOpen ? '1.5rem' : '' }} className={isOpen ? classes.topSectionOpen : classes.topSection}>
        <div
          className={classes.dociphiIcon}
          style={{ margin: isOpen ? '' : '0rem auto' }}
        >
          {isOpen ? '' : <img src={SidebarLogo} alt="Bars" />}
          {isOpen ? <div className={`${classes.menu} ${'h3Regular'}`}><img src={DociphiLogoWhite} alt="Dociphi" /></div> : ''}
        </div>
      </div>
      <div
        className={classes.bars}
        style={{ left: isOpen ? '13.5rem' : '2.5rem' }}
        onClick={toggle}
        aria-hidden="true"
      >
        {isOpen ? <img src={OpenedMenu} alt="close menu" /> : <img src={ClosedMenu} alt="open menu" />}
      </div>
      <div className={classes.sidebarItems}>
        <div>
          <div className={`${isOpen ? classes.generalText : classes.generalTextHidden} ${'h4Regular'}`}>
            General
          </div>
          {
            userRole === 'loan_administrator'
              ? sidebarTopItemLoanAdmin.map((item) => (
                <NavLink
                  to={item.path}
                  key={item.name}
                  style={{ paddingLeft: isOpen ? '0.7rem' : '' }}
                  className={({ isActive }) => (isActive ? classes.activeBtn : classes.link)}
                >
                  {
                    isOpen
                      ? (
                        <div className={
                          isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                        }
                        >
                          {item.icon}
                        </div>
                      )
                      : (
                        <CustomizedTooltip text={item.name}>
                          <div className={
                            isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                          }
                          >
                            {item.icon}
                          </div>
                        </CustomizedTooltip>
                      )
                  }
                  <div style={{ display: isOpen ? 'block' : 'none' }}>{item.name}</div>
                </NavLink>
              ))
              : sidebarTopItem.map((item) => (
                <NavLink
                  to={item.path}
                  key={item.name}
                  style={{
                    paddingLeft: isOpen ? '0.7rem' : '', font: 'Gotham', fontWeight: '700', fontSize: '14', lineHeight: '14.3px',
                  }}
                  className={({ isActive }) => (isActive ? classes.activeBtn : classes.link)}
                >
                  {
                    isOpen
                      ? (
                        <div className={
                          isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                        }
                        >
                          {item.icon}
                        </div>
                      )
                      : (
                        <CustomizedTooltip text={item.name}>
                          <div className={
                            isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                          }
                          >
                            {item.icon}
                          </div>
                        </CustomizedTooltip>
                      )
                  }
                  <div style={{ display: isOpen ? 'block' : 'none', transitionDelay: '10s', transition: 'width 3s' }}>
                    {item.name}
                  </div>
                </NavLink>
              ))
          }
        </div>
        <div>
          <div className={`${isOpen ? classes.generalText : classes.generalTextHidden} ${'h4Regular'}`}>
            Account
          </div>
          {/* Help */}
          <div
            style={{ paddingLeft: isOpen ? '0.7rem' : '' }}
            className={isHelpOpen ? classes.activeBtn : classes.link}
            onClick={(e) => {
              setIsHelpOpen(!isHelpOpen);
              setAnchorElHelp(e.currentTarget);
            }}
            aria-hidden
          >
            {isOpen ? (
              <div
                className={
                  isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                }
              >
                <img src={UserGuide} alt="help" height="25rem" width="25rem" />
              </div>
            )
              : (
                <CustomizedTooltip text="Help">
                  <div
                    className={
                      isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                    }
                  >
                    <img src={UserGuide} alt="help" height="25rem" width="25rem" />
                  </div>
                </CustomizedTooltip>
              )}
            <div style={{ display: isOpen ? 'block' : 'none' }}>Help</div>
          </div>
          {/* Settings */}
          {
            userRole === 'Super Admin'
            && (
              <div
                style={{ paddingLeft: isOpen ? '0.7rem' : '' }}
                className={isSettingsOpen || isSettingsActive() ? classes.activeBtn : classes.link}
                onClick={(e) => {
                  setIsSettingsOpen(!isSettingsOpen);
                  setAnchorElSettings(e.currentTarget);
                }}
                aria-hidden
              >
                {isOpen ? (
                  <div
                    className={
                      isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                    }
                  >
                    <img src={SettingsIcon} alt="Settings" height="25rem" width="25rem" />
                  </div>
                )
                  : (
                    <CustomizedTooltip text="Settings">
                      <div
                        className={
                          isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                        }
                      >
                        <img src={SettingsIcon} alt="Settings" height="25rem" width="25rem" />
                      </div>
                    </CustomizedTooltip>
                  )}
                <div style={{ display: isOpen ? 'block' : 'none' }}>Settings</div>
              </div>
            )
          }
          {/* Notifications */}
          <div
            style={{ paddingLeft: isOpen ? '0.7rem' : '' }}
            className={isNotifOpen ? classes.activeBtn : classes.link}
            onClick={(e) => {
              setIsNotifOpen(!isNotifOpen);
              setAnchorEl(e.currentTarget);
            }}
            aria-hidden
          >
            {isOpen ? (
              <div
                className={
                  isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                }
                style={{ position: 'relative' }}
              >
                <img src={notificationIcon} alt="Icon" height="25rem" width="25rem" />
                {getUnresolvedNotificationsCount() !== 0 && (
                  <div className={classes.notificationTag}>{getUnresolvedNotificationsCount()}</div>
                )}
              </div>
            )
              : (
                <CustomizedTooltip text={isOpen ? '' : 'Notifications'}>

                  <div
                    className={
                      isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                    }
                    style={{ position: 'relative' }}
                  >
                    <img src={notificationIcon} alt="Icon" height="25rem" width="25rem" />
                    {getUnresolvedNotificationsCount() !== 0 && (
                      <div className={classes.notificationTag}>
                        {getUnresolvedNotificationsCount()}
                      </div>
                    )}
                  </div>
                </CustomizedTooltip>
              )}
            <div style={{ display: isOpen ? 'block' : 'none' }}>Notifications</div>
          </div>
          {/* Profile */}
          <div
            className={classes.profilePopover}
            style={{ paddingLeft: isOpen && '0.7rem', justifyContent: !isOpen && 'center' }}
            onClick={(e) => {
              setIsProfileOpen(!isProfileOpen);
              setAnchorElProfile(e.currentTarget);
            }}
            aria-hidden
          >
            <div
              className={classes.profilePopoverDiv}
              style={{
                marginLeft: isOpen && '0.7rem', marginBottom: isOpen ? '1rem' : '0.5rem',
              }}
            >
              {isOpen ? (
                <div style={{ display: 'flex', gap: '1rem', marginTop: '0.2rem' }}>
                  <div
                    className={
                      isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                    }
                  >
                    <div>
                      <div className={classes.greenIcon}>
                        <img src={GreenIcon} alt="" />
                      </div>
                      <img src={User} alt="user" height="25rem" width="25rem" />
                    </div>
                  </div>
                  <div className={`${classes.profileDetails}`}>
                    <div className={`${classes.profileName} ${'h3Medium'}`} style={{ color: 'white' }}>{`${localStorage.firstName} ${localStorage.lastName}`}</div>
                    <div className={`${classes.onlineText} ${'h5Regular'}`} style={{ color: 'white' }}>Online</div>
                  </div>
                </div>
              )
                : (
                  <CustomizedTooltip text="Profile">
                    <div
                      className={
                        isOpen ? classes.expandDrawerIcon : classes.collapseDrawerIcon
                      }
                    >
                      <div
                        // onClick={handleClick}
                        aria-hidden
                      >
                        <div className={classes.greenIcon}>
                          <img src={GreenIcon} alt="" />
                        </div>
                        <img src={User} alt="user" height="25rem" width="25rem" />
                      </div>
                    </div>
                  </CustomizedTooltip>
                )}
            </div>
          </div>
        </div>
      </div>
      <NotificationPopover
        isOpen={isNotifOpen}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setIsNotifOpen={setIsNotifOpen}
        setUnresolvedNotifications={setUnresolvedNotifications}
        unresolvedNotifications={unresolvedNotifications}
      />
      {isHelpOpen && (
        <HelpPopover
          anchorEl={anchorElHelp}
          setAnchorEl={setAnchorElHelp}
          setIsHelpOpen={setIsHelpOpen}
        />
      )}
      {isSettingsOpen && (
        <SettingsPopover
          anchorEl={anchorElSettings}
          setAnchorEl={setAnchorElSettings}
          setIsSettingsOpen={setIsSettingsOpen}
        />
      )}
      {isProfileOpen && (
        <ProfileDropdownPopover
          anchorEl={anchorElProfile}
          setAnchorEl={setAnchorElProfile}
          setIsProfileOpen={setIsProfileOpen}
        />
      )}
    </div>
  );
}
