import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Sidebar } from './components';
import { useStyles } from './Styles';

export default function Main() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    setUserRole(localStorage.getItem('userRole'));
    if (localStorage.getItem('firstName') === null) {
      navigate('/login');
    }
  });
  return (
    <div className={classes.flex}>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} userRole={userRole} />
      <div
        style={{
          width: isOpen ? 'calc(100vw - 15.65rem)' : 'calc(100vw - 4.65rem)', position: 'relative', left: isOpen ? '15rem' : '4rem', overflowX: 'clip',
        }}
        className={classes.flexColumn}
      >
        <main className={classes.content}>
          <Outlet />
        </main>
      </div>
    </div>
  );
}
