import { useEffect } from 'react';
import { JiraWidget, JiraWidgetName } from '../services/constants/constants';

const useTicketingTool = () => {
  useEffect(() => {
    const showTicketingTool = process.env.REACT_APP_TICKETING_TOOL_ENABLE;

    if (showTicketingTool === 0) {
      return;
    }

    let script = document.getElementById(JiraWidget);
    if (script) {
      return;
    }

    script = document.createElement('script');

    script.src = process.env.REACT_APP_TICKETING_TOOL_SRC;
    script.id = JiraWidget;
    script.setAttribute('async', true);
    script.setAttribute('data-jsd-embedded', '');
    script.setAttribute('data-key', process.env.REACT_APP_TICKETING_TOOL_DATA_KEY);
    script.setAttribute('data-base-url', process.env.REACT_APP_TICKETING_TOOL_DATA_BASE_URL);

    document.body.appendChild(script);

    script.addEventListener('load', () => {
      window.document.dispatchEvent(new Event('DOMContentLoaded', {
        bubbles: true,
        cancelable: true,
      }));
      document.getElementsByName(JiraWidgetName)[0].style.display = 'block';
    });
    // eslint-disable-next-line consistent-return
    return () => {
      const widgetScript = document.getElementById(JiraWidget);
      if (widgetScript && document.getElementsByName(JiraWidgetName)[0]) {
        document.getElementsByName(JiraWidgetName)[0].style.display = 'none';
        widgetScript.remove();
      }
    };
  }, []);
};

export default useTicketingTool;
