import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CircularLoading({
  color = 'white', size = '3vh', isExt = false, loading,
}) {
  return (
    isExt && loading
      ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={size} style={{ color }} />
        </Box>
      )
      : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={size} style={{ color }} />
        </Box>
      )
  );
}

export default CircularLoading;
