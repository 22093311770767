import React from 'react';
import { Outlet } from 'react-router-dom';
import { PageContextProvider } from '../context/PageContext';

function PageContextOutlet() {
  return (
    <PageContextProvider>
      <Outlet />
    </PageContextProvider>
  );
}

export default PageContextOutlet;
