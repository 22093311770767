const grayDark1 = '#15284B';
const grayDark2 = '#3C4C68';
const grayDark3 = '#616E84';
const grayMid1 = '#8992A1';
const grayMid2 = '#AEB3BC';
const grayMid3 = '#D4D5D8';
const grayMid4 = '#E1E2E2';
const grayLight1 = '#F1F3F4';
const grayLight2 = '#FBFBFB';
const white = '#FFFFFF';
const blue = '#64E9F2';
const blueGrayMid = '#E4E6EF';
const blueGrayLightMid = '#EAECF3';
const blueGrayLightDark = '#DCE0F1';
const blueGrayLight = '#F5F8FA';
const blueGrayDark = '#CBCFE0';
const blueCobaltDark = '#2D4081';
const blueCobaltMid = '#395DAB';
const blueCobaltMidOpacity = '#9CAED5';
const blueCobaltLight = '#5892F9';
const blueCobaltLightOpacity = '#ACC9FC';
const blueDefault = '#0065FF';
const blueLight1 = '#C2DBFF';
const blueLight2 = '#E8F0FB';
const blueLight3 = '#F1F7FF';
const blueLight4 = '#FAFCFF';
const green = '#46E593';
const greenDefault = '#24B082';
const greenDefaultOpacity = '#92D8C1';
const greenLight = '#D4F1E8';
const greenLight1 = '#46E593';
const redDefault = '#DE350B';
const redDefaultOpacity = '#EF9A85';
const redLight = '#F6E0DB';
const yellowDefault = '#FFAB00';
const yellowDefaultOpacity = '#FFD580';
const yellowLight = '#FADEA7';
const yellowExtremelight = '#FDF5E5';
const orange = '#D9AB79';
const orangeDefault = '#D6792D';
const orangeDefaultOpacity = '#EBBC96';
const orangeMid = '#FF9E4F';
const orangeMidOpacity = '#FFCFA7';
const orangeLight = '#F6E2D2';
const purpleDefault = '#6554C0';
const purpleDefaultOpacity = '#B2AAE0';
const purpleMid = '#9686EA';
const purpleLight = '#E5E2FC';
const brownDefault = '#624545';
const brownMid = '#9B7676';
const brownLight = '#E0D4D4';
const brownLight1 = '#D9AB79';
const greenLightDefault = '#E1E2E2';
const lightGrey = '#F4F4F4';
const greenLight2 = '#D4F1E8';
const darkBlue = '#07232b';
const blueLightDefault = '#8BD4FE';
const purpleDefaultDark = '#9B51CC';
const voiletDark = '#3A0CA3';
const darkPurple = '#B5179E';
const blueDefaultDark = '#1C2B5F';
const blueDefaultLight = '#009DFF';
const moonMist = '#C9D9E0';

const colors = {
  grayDark1,
  grayDark2,
  grayDark3,
  grayMid1,
  grayMid2,
  grayMid3,
  grayMid4,
  grayLight1,
  grayLight2,
  white,
  blue,
  blueGrayMid,
  blueGrayLightMid,
  blueGrayLightDark,
  blueGrayLight,
  blueGrayDark,
  blueCobaltDark,
  blueCobaltMid,
  blueCobaltLight,
  blueDefault,
  blueLight1,
  blueLight2,
  blueLight3,
  blueLight4,
  green,
  greenDefault,
  greenLight,
  greenLight1,
  redDefault,
  redLight,
  yellowDefault,
  yellowLight,
  yellowExtremelight,
  orange,
  orangeDefault,
  orangeMid,
  orangeLight,
  purpleDefault,
  purpleMid,
  purpleLight,
  brownDefault,
  brownMid,
  brownLight,
  brownLight1,
  // Below colors are of opacity value 0.5 of their original color
  blueCobaltMidOpacity,
  redDefaultOpacity,
  orangeMidOpacity,
  orangeDefaultOpacity,
  blueCobaltLightOpacity,
  purpleDefaultOpacity,
  greenDefaultOpacity,
  yellowDefaultOpacity,
  greenLightDefault,
  lightGrey,
  greenLight2,
  darkBlue,
  blueLightDefault,
  purpleDefaultDark,
  voiletDark,
  darkPurple,
  blueDefaultDark,
  blueDefaultLight,
  moonMist,
};
export default colors;
