const SectionNames = {
  EMPTY: '',
  EMPTY_SECTION: 'empty section',
  INVOICE_NUMBER: 'Invoice number',
  DIAGNOSIS: 'Diagnosis',
  BODY_PART: 'Body Part',
  INJURY: 'Injury',
  MED_PROCEDURE: 'Med Procedure',
  ILLNESS: 'Illness',
  AMOUNT: 'Amount',
  PRESENCE_OF_THE_BOLLO_TAX: 'Presence Of The Bollo Tax',
  DESCRIPTION_OF_THE_SERVICE: 'Description of the service',
  TYPE_OF_THE_MEDICAL_SERVICE: 'Type of the medical service',
};

const TypeOfTheMedicalServices = [
  'VISITE SPECIALISTICHE, ACCERTAMENTI DIAGNOSTICI, CURE E ALTRE TERAPIE',
  'RICOVERO CON / SENZA INTERVENTO',
  'LENTI / OCCHIALI',
  'CURE DENTARIE',
  'CURE ONCOLOGICHE',
  'PRESTAZIONI DI ALTA DIAGNOSTICA',
];

const TypeOfTheMedicalServicesObj = {
  VISITE_SPECIALISTICHE: 'VISITE SPECIALISTICHE, ACCERTAMENTI DIAGNOSTICI, CURE E ALTRE TERAPIE',
  RICOVERO: 'RICOVERO CON / SENZA INTERVENTO',
  LENTI: 'LENTI / OCCHIALI',
  CURE_DENTARIE: 'CURE DENTARIE',
  CURE_ONCOLOGICHE: 'CURE ONCOLOGICHE',
  PRESTAZIONI: 'PRESTAZIONI DI ALTA DIAGNOSTICA',
};

const DescriptionOfTheServices = [
  [
    'Esami di laboratorio',
    'Fisioterapia effettuata da Fisioterapista',
    'Altre terapie',
    'Medicinali',
    'Visita specialistica',
    'Altri accertamenti diagnostici',
  ],
  [
    'Ricovero',
    'Equipe chirurgica',
    'Visite specialistiche pre/post',
    'Accertamenti diagnostici pre/post',
    'Medicinali pre/post',
    'Trasporto da/verso istituto di cura',
    'Diaria surrogatoria ricovero SSN',
    'Day Surgery/Day Hospital',
  ],
  [
    'Lenti/Occhiali',
    'Lenti a contatto',
  ],
  [
    'Prestazioni Odontoiatriche',
    'Prestazioni Ortodontiche',
    'Esami radiologici odontoiatrici',
  ],
  [
    'CURE ONCOLOGICHE IN RETE',
    'CURE ONCOLOGICHE',
    'CURE ONCOLOGICHE INTEGR',
    'CURE ONCOLOGICHE TICKET',
    'TERAPIE GENERICHE',
  ],
  [
    'Altra prestazione di alta diagnostica',
    'Holter',
    'Laserterapia',
    'MOC',
    'RMN',
    'Scintigrafia',
    'PET',
    'TAC',
    'Amniocentesi',
    'Doppler',
    'Ecocardiografia',
    'Esami Endoscopici',
  ],

];

const HitlStatus = {
  HIDE: 'hide',
  DISABLE: 'disable',
  ENABLE: 'enable',
};

const DownloadTypes = [
  {
    key: '',
    value: 'Download',
    hidden: true,
    selected: true,
  },
  {
    key: 'Download as JSON',
    value: 'Download as JSON',
    hidden: false,
    selected: false,
  },
  {
    key: 'Download as XLSX',
    value: 'Download as XLSX',
    hidden: false,
    selected: false,
  },
];

const PipelineRetryCount = 50;

const INSURANCE_DOCS = ['property insurance', 'hazard insurance', 'insurance',
  'homeowners insurance policy', 'evidence of insurance',
  'lexisnexis', 'flood insurance', 'flood policy'];

const ENTITIES = ['No of Buildings', 'No of Units', 'no_of_buildings/nfip'];

const JiraWidget = 'jiraWidget';
const JiraWidgetName = 'JSD widget';

const AllLoansFilter = [
  {
    panel: 'product_type',
    checkedData: [],
  },
  {
    panel: 'loan_type',
    checkedData: [],
  },
  // {
  //   panel: 'note_account_number',
  //   checkedData: [],
  // },
  // {
  //   panel: 'borrower_name',
  //   checkedData: [],
  // },
  // {
  //   panel: 'borrower_email',
  //   checkedData: [],
  // },
  // {
  //   panel: 'account_address',
  //   checkedData: [],
  // },
  {
    panel: 'created_on',
    checkedData: [],
  },
  {
    panel: 'portfolio_type',
    checkedData: [],
  },
  {
    panel: 'document_type',
    checkedData: [],
  },
  // {
  //   panel: 'expiry_date',
  //   checkedData: [],
  // },
  // {
  //   panel: 'flood_zone',
  //   checkedData: [],
  // },
  {
    panel: 'compliance',
    checkedData: [],
  },
  {
    panel: 'status',
    checkedData: [],
  },
];

export {
  TypeOfTheMedicalServices,
  DescriptionOfTheServices,
  TypeOfTheMedicalServicesObj,
  SectionNames,
  DownloadTypes,
  HitlStatus,
  PipelineRetryCount,
  INSURANCE_DOCS,
  ENTITIES,
  JiraWidget,
  JiraWidgetName,
  AllLoansFilter,
};
