import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  // sidebar
  sidebar: {
    background: theme.palette.blueCobaltDark,
    color: theme.palette.white,
    // minHeight: '84%',
    height: '100%',
    zIndex: 1,
  },
  sidebarBottom: {
    background: theme.palette.blueCobaltDark,
    color: theme.palette.white,
    bottom: '0',
    height: '22vh',
  },
  main: {
    width: '100%',
    padding: '1.25rem',
  },
  topSection: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: '1rem',
    // marginBottom: '0.5rem',
    height: '10%',
    paddingTop: '0.5rem',
  },
  topSectionOpen: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: '1rem',
    // marginBottom: '1rem',
    height: '10%',
    marginLeft: '1.4rem',
    paddingTop: '0.5rem',
  },
  bars: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    left: '2.5rem',
    position: 'relative',
    width: 'min-content',
    // height: '2.5rem',
    // marginLeft: '1.2rem',
    height: '5%',
    transition: '0.5s ease',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white,
    textDecoration: 'none',
    marginLeft: '0.7rem',
    marginRight: '0.7rem',
    marginBottom: '0.5rem',
    gap: '1rem',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: '600',
    transition: 'transform 1.5s',
    height: '2.5rem',
    '&:hover': {
      background: theme.palette.blueCobaltMid,
      border: `1px solid ${theme.palette.blueCobaltLight}`,
      borderRadius: '0.25rem',
    },
    cursor: 'pointer',
  },
  activeBtn: {
    height: '2.5rem',
    background: theme.palette.blueCobaltMid,
    border: `1px solid ${theme.palette.blueCobaltLight}`,
    borderRadius: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white,
    textDecoration: 'none',
    marginLeft: '0.7rem',
    marginRight: '0.7rem',
    marginBottom: '0.5rem',
    gap: '1rem',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: '600',
    cursor: 'pointer',
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white,
    textDecoration: 'none',
    marginLeft: '1.25rem',
    marginRight: '0.7rem',
    gap: '1rem',
    transition: 'transform 0.5s',
  },
  expandDrawerIcon: {
    display: 'flex',
    alignItems: 'center',
    height: '2.5rem',
  },
  collapseDrawerIcon: {
    display: 'flex',
    alignItems: 'center',
    height: '2.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  // main
  content: {
    background: '#F5F5F5',
    minHeight: '100vh',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: '1rem',
    transition: 'ease-in 0.2s',
  },
  flex: {
    display: 'flex',
  },
  // navbar
  navbar: {
    display: 'flex',
    alignItems: 'center',
    height: '3.5rem',
  },
  leftNav: {
    flexGrow: '1',
    marginLeft: '2.5rem',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  rightIcons: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: '1.25rem',
  },
  notificationPopover: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.5rem',
  },
  dropDown: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '0.8rem',
    '&:hover': {
      background: theme.palette.grayLight1,
      borderRadius: '4.188rem',
    },
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    flexDirection: 'row',
    height: '4rem',
  },
  profileImage: {
    margin: '0.5rem 0rem 0rem 0.5rem',
  },
  profileDetails: {
    marginRight: '1rem',
    cursor: 'pointer',
  },
  profileName: {
    color: theme.palette.grayDark1,
  },
  onlineText: {
    color: theme.palette.grayDark2,
  },
  logout: {
    padding: '0.5rem 2rem 0.5rem 0.5rem',
    color: theme.palette.grayDark1,
    cursor: 'pointer',
    borderTop: `1px solid ${theme.palette.grayMid3}`,
  },
  sidebarItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '85%',
  },
  generalText: {
    color: theme.palette.blueGrayLight,
    margin: '0.5rem 0.7rem 0.5rem 1.2rem',
  },
  generalTextHidden: {
    visibility: 'hidden',
    color: theme.palette.blueGrayLight,
    margin: '0.5rem 0.7rem 0.5rem 1.2rem',
  },
  closeSidebarBottomItem: {
    display: 'flex',
    flexDirection: 'column',
    height: '-webkit-fill-available',
    justifyContent: 'flex-end',
  },

  profilePopover: {
    display: 'flex',
    gap: '1rem',
    transition: 'transform 0.5s',
    background: '#3F55A0',
    height: '3.6875rem',
    lineHeight: '1.5rem',
    alignItems: 'center',
    fontSize: '0.875rem',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  profilePopoverDiv: {
    display: 'flex',
    gap: '1rem',
    height: '2rem',
  },
  userAction: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white,
    textDecoration: 'none',
    marginLeft: '1.25rem',
    marginRight: '0.7rem',
    marginBottom: '0.5rem',
    gap: '1rem',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: '600',
    height: '2.5rem',
    '&:hover': {
      background: 'none',
      border: '0px',
      borderRadius: '0rem',
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white,
    textDecoration: 'none',
    marginLeft: '1rem',
    marginRight: '0.7rem',
    marginBottom: '0.5rem',
    gap: '1rem',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: '600',
    height: '2.5rem',
    '&:hover': {
      background: 'none',
      border: '0px',
      borderRadius: '0rem',
    },
    sideBarIcon: {
      display: 'flex',
    },
  },
  greenIcon: {
    display: 'flex',
    position: 'relative',
    left: '1.2rem',
    top: '0.4rem',
    zIndex: '2',
  },
  helpItem: {
    padding: '0.5rem 0rem 0.5rem 1rem',
    cursor: 'pointer',
    width: '12.8rem',
    color: theme.palette.white,
    background: theme.palette.blueCobaltMid,
    '&:hover': {
      background: theme.palette.blueCobaltDark,
    },
  },
  notificationTag: {
    position: 'absolute',
    left: '40%',
    bottom: '60%',
    color: 'white',
    background: 'red',
    padding: '0',
    borderRadius: '50%',
    width: '75%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: '55%',
    fontSize: '9px',
  },
}));
