import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import fileIcon from '../../../assets/Popup/fileIcon.svg';
import ButtonOutlined from '../Button/ButtonOutlined';
import resolvedIcon from '../../../assets/resolved.svg';
import unresolvedIcon from '../../../assets/unresolved.svg';
import CustomizedTooltip from '../Tooltip/CustomizedTooltip';

export default function NotificationDropdownContent(props) {
  const {
    data, handleResolved, handleClose,
  } = props;
  // const navigate = useNavigate();
  const useStyles = makeStyles((theme) => ({
    icon: {
      height: '0.893rem',
      width: '0.893rem',
      marginRight: '0.2rem',
      marginTop: '0.3rem',
    },
    notificationText: {
      color: theme.palette.grayDark1,
    },
    notificationContent: {
      color: theme.palette.grayDark2,
      marginBottom: '0.5rem',
    },
    time: {
      color: theme.palette.grayDark3,
      marginBottom: '0.4rem',
    },
    dropdownContent: {
      borderBottom: `1px solid ${theme.palette.grayMid3}`,
      display: 'flex',
      flexDirection: 'row',
      padding: '0.5rem',
      marginBottom: '0.5rem',
    },
    fileIcon: {
      marginRight: '0.5rem',
    },
    hour: {
      marginRight: '0.5rem',
    },
    viewAndResolvedContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    resolvedUnresolvedIcon: {
      cursor: 'pointer',
    },
    resolvedBy: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.2rem',
    },
  }));

  const date = new Date(data?.created_at);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  const time = date.toLocaleTimeString([], options);

  const currentTime = new Date();
  // currentTime.setMinutes(currentTime.getMinutes() - currentTime.getTimezoneOffset());
  const diffInMs = currentTime.getTime() - date.getTime();
  const diffInHours = Math.floor(diffInMs / 3600000);
  const diffInMinutes = Math.floor(diffInMs / 60000);

  const [displayTime, setDisplayTime] = useState();
  const [displayText, setDisplayText] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (diffInHours > 24) {
      setDisplayTime(Math.floor(diffInHours / 24));
      setDisplayText(Math.floor(diffInHours / 24) === 1 ? 'day ago' : 'days ago');
    } else if (diffInHours < 1) {
      setDisplayTime(diffInMinutes);
      setDisplayText(diffInMinutes === 1 ? 'minute ago' : 'minutes ago');
    } else {
      setDisplayTime(diffInHours);
      setDisplayText(diffInHours === 1 ? 'hour ago' : 'hours ago');
    }
  }, [data?.created_at]);

  const classes = useStyles();

  const goToLoanDetails = () => {
    handleClose();
    navigate(`/all-loans/loan-details?appId=${data?.application_id}&ingId=${data?.ingestion_id}`);
  };

  return (
    <div className={classes.dropdownContent}>
      <div className={classes.fileIcon}><img src={fileIcon} alt="FileIcon" /></div>
      <div>
        <div className={`${classes.notificationContent} ${'h4Regular'}`}>
          {data?.description}
        </div>
        <div className={`${classes.time} ${'h5Regular'}`}>
          <span className={classes.hour}>
            {displayTime}
            {' '}
            {displayText}
          </span>
          {data?.is_read}
          <span>{time}</span>
        </div>
        {
          (data.application_id || data.ingestion_id)
          && (
            <div className={classes.viewAndResolvedContainer}>
              <span>
                <ButtonOutlined text="View" onClick={goToLoanDetails} />
              </span>
              {
                data.is_task && (
                  data.resolved_by ? (
                    <span className={`${classes.time} ${classes.resolvedBy} ${'h5Regular'}`}>
                      <img src={resolvedIcon} alt="Resolved" height="11px" />
                      Resolved by
                      {' '}
                      {data.user_name}
                    </span>
                  )
                    : (
                      <span
                        className={classes.resolvedUnresolvedIcon}
                        onClick={() => handleResolved(data.notification_id)}
                        aria-hidden
                      >
                        <CustomizedTooltip text="Mark as resolved">
                          <img src={unresolvedIcon} alt="Unresolved" />
                        </CustomizedTooltip>
                      </span>
                    )
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
}
