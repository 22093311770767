// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import React from 'react';
import './index.css';
// import { Provider } from 'react-redux';
import { FirebaseAppProvider } from 'reactfire';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// redux setup

// import { store } from './redux/store';
// IDP setup using reactfire

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize React 18
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

function Index() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  );
}

root.render(
  // <Provider store={store}>
  <Index />,
  // </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
