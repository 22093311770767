import { Skeleton } from '@mui/material';
import React from 'react';

export default function CircularSkeleton(props) {
  /* adjust the size with width and height */
  const { height, width = '100%' } = props;
  return (
    <Skeleton variant="circular" width={width} height={height} />
  );
}
