import { Popover } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { useStyles } from '../Styles';

const StyledPopover = styled(Popover)(() => ({
  '& .MuiPaper-root': {
    marginLeft: '0.7rem',
    borderRadius: '0rem 0.25rem 0.25rem 0rem',
  },
}));

export default function HelpPopover(props) {
  const {
    // isOpen,
    anchorEl, setAnchorEl, setIsHelpOpen,
  } = props;

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setIsHelpOpen(false);
  };

  const handleDownload = (href) => {
    const a = document.createElement('A');
    a.href = href;
    a.download = href.substr(href.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div
        className={`${classes.helpItem} h3Regular`}
        style={{ borderBottom: '1px solid #AEB3BC' }}
        onClick={() => handleDownload('/Dociphi LIT User Guide.pdf')}
        aria-hidden
      >
        User guide
      </div>
      <div
        className={`${classes.helpItem} h3Regular`}
        onClick={() => handleDownload('/Dociphi LIT FAQ.pdf')}
        aria-hidden
      >
        FAQ&apos;s
      </div>
    </StyledPopover>
  );
}
