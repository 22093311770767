import React, { useEffect } from 'react';
import {
  Outlet, Navigate, useLocation, useNavigate,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useUserAuth } from '../context/UserAuthContext';
import CircularLoading from '../components/atoms/Loader/CircularLoading';
import useTicketingTool from '../hooks/useTicketingTool';
import useIdle from '../hooks/useIdle';
import useAxios from '../hooks/useAxios';
import { authService } from '../constants/urls';

export const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    height: '90vh',
    justifyContent: 'center',
  },
}));

function ProtectedOutlet() {
  const { user, loaded, logOut } = useUserAuth();
  const axiosPrivate = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const Classes = useStyles();
  useTicketingTool();

  const handlePostLogout = (autoLogout) => {
    const payload = {
      session_id: localStorage.getItem('sessionId'),
      auto_logout: autoLogout,
    };

    axiosPrivate.post(authService.LOGOUT, payload)
      .then((response) => {
        if (response.data.status) {
          navigate('/login', { state: { from: location } });
          window.localStorage.clear();
          sessionStorage.clear();
        }
      })
      .catch(() => {
        // handle error here
      });
  };

  const handleLogout = (autoLogout) => {
    logOut();
    handlePostLogout(autoLogout);
  };
  // for auto logout - 1 hrs inactivity and sync tab every 1 min
  const isIdle = useIdle(1 * 3600 * 1000, 60 * 1000);

  useEffect(() => {
    if (isIdle && !window.location.href.includes('/login')) {
      handleLogout(true);
    }
  }, [isIdle]);

  const fetchOutlet = () => {
    if (loaded) {
      if (user) {
        return <Outlet />;
      }
      return <Navigate to="/login" />;
    }
    return (
      <div className={Classes.loader}>
        <CircularLoading color="0065FF" size="10vh" />
      </div>
    );
  };
  return (
    fetchOutlet()
  );
}

export default ProtectedOutlet;
