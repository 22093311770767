import { Popover } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStyles } from '../Styles';

const StyledPopover = styled(Popover)(() => ({
  '& .MuiPaper-root': {
    marginLeft: '0.7rem',
    borderRadius: '0rem 0.25rem 0.25rem 0rem',
  },
}));

export default function SettingsPopover(props) {
  const {
    // isOpen,
    anchorEl, setAnchorEl, setIsSettingsOpen,
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setIsSettingsOpen(false);
  };

  const handleNavigate = (to) => {
    navigate(to, { state: { from: location } });
    handleClose();
  };

  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div
        className={`${classes.helpItem} h3Regular`}
        style={{ borderBottom: '1px solid #AEB3BC' }}
        onClick={() => handleNavigate('/user-management')}
        aria-hidden
      >
        User Management
      </div>
      <div
        className={`${classes.helpItem} h3Regular`}
        onClick={() => handleNavigate('/compliance-rule-preset')}
        aria-hidden
      >
        CRP
      </div>
    </StyledPopover>
  );
}
