import React from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../config/axios';
import { useUserAuth } from '../context/UserAuthContext';

const useAxios = () => {
  const { user, loaded } = useUserAuth();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!loaded) {
      return;
    }

    // const requestIntercept = axiosPrivate.interceptors.request.use(async (config) => {
    //   const token = await user.current.getIdToken();

    //   if (!config.headers.Authorization) {
    //     config.headers.Authorization = `Bearer ${token}`;
    //   }
    //   return config;
    // }, (error) => Promise.reject(error));

    // const requestIntercept = axiosPrivate.interceptors.request.use(async (config) => {
    //   if (user.current === null) {
    //     navigate('/login');
    //   } else {
    //     const token = await user.current.getIdToken();

    //     if (!config.headers.Authorization) {
    //       const updatedConfig = {
    //         ...config,
    //         headers: {
    //           ...config.headers,
    //           Authorization: `Bearer ${token}`,
    //         },
    //       };
    //       return updatedConfig;
    //     }
    //   }
    //   return config;
    // }, (error) => Promise.reject(error));

    const requestIntercept = axiosPrivate.interceptors.request.use(async (config) => {
      if (user.current === null) {
        navigate('/login');
      }
      const token = await user.current.getIdToken();
      const conf = config;

      if (!conf.headers.Authorization) {
        conf.headers.Authorization = `Bearer ${token}`;
      }
      return conf;
    }, (error) => Promise.reject(error));

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const previousRequest = error?.config;
        if (error?.response?.status === 403 && !previousRequest?.sent) {
          previousRequest.sent = true;
        }

        return Promise.reject(error);
      },
    );

    // eslint-disable-next-line consistent-return
    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, user, loaded);

  return axiosPrivate;
};

export default useAxios;
