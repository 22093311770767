import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/styles';
// import Typography from '@mui/material/Typography';
// import Badge from '../Badge/Badge';
import NotificationDropdown from '../Dropdown/NotificationDropdown';
// import notificationIcon from '../../../assets/notificationIcon.svg';
import useAxios from '../../../hooks/useAxios';
import { applicationService } from '../../../constants/urls/endpoints';
import Toaster from '../Toaster/Toaster';

const StyledPopover = styled(Popover)(() => ({
  '& .MuiPaper-root': {
    marginLeft: '0.75rem',
  },
  '& .MuiPopover-paper': {
    maxHeight: 'calc(100% - 132px)',
    '@media (min-width: 1280px)': {
      maxHeight: 'calc(100% - 132px)',
    },
    '@media (min-width: 1366px)': {
      maxHeight: 'calc(100% - 182px)',
    },
    '@media (min-width: 1440px)': {
      maxHeight: 'calc(100% - 162px)',
    },
    '@media (min-width: 1966px)': {
      maxHeight: 'calc(100% - 182px)',
    },
  },
}));

export default function NotificationPopover(props) {
  const {
    isOpen,
    anchorEl, setAnchorEl, setIsNotifOpen, unresolvedNotifications, setUnresolvedNotifications,
  } = props;

  const openNotif = Boolean(anchorEl);
  const id = openNotif ? 'simple-popover' : undefined;

  const axiosPrivate = useAxios();
  const [notificationData, setNotificationData] = React.useState({});
  const [loading, setLoading] = useState(false);
  // Alert states
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // state to check if more notifications are present
  const [totalPages, setTotalPages] = useState();

  const showErrorMessage = (toasterMsg) => {
    setMessage(toasterMsg);
    setOpen(true);
    setStatus('error');
  };
  const getNotifications = () => {
    setLoading(true);
    axiosPrivate.get(
      // first parameter is page size (currenlty set to 6), 2nd is currentPage
      applicationService.GET_NOTIFICATION(6, currentPage),
    )
      .then((response) => {
        const notifs = response?.data?.data?.notifications;
        // to concatenate notifications array of next page to already present notifications array
        // setNotificationData((prevState) => [...prevState, ...notifs]);
        setNotificationData((prevState) => {
          const prevStateCp = JSON.parse(JSON.stringify(prevState));
          prevStateCp[currentPage] = notifs;
          return prevStateCp;
        });
        setTotalPages(response?.data?.data?.total_pages);
        setUnresolvedNotifications(response?.data?.data?.unresolved_notifications);
        setLoading(false);
      })
      .catch((err) => {
        showErrorMessage(err?.response?.data?.message ? err.response.data.message : err.message);
      });
  };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
    setIsNotifOpen(false);
    // to clear all data from state when we close
    setNotificationData({});
    setCurrentPage(1);
  };

  useEffect(() => {
    if (openNotif) {
      getNotifications();
    }
  }, [currentPage, openNotif]);

  const handleResolved = (notificationId) => {
    axiosPrivate.put(applicationService.RESOLVED_NOTIFICATION(notificationId))
      .then((response) => {
        if (response?.data?.status) {
          getNotifications();
        }
      })
      .catch((err) => {
        showErrorMessage(err?.response?.data?.message ? err.response.data.message : err.message);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    isOpen && (
    <div style={{ display: 'flex', gap: '1rem' }}>
      {/* <div onClick={handleClick} aria-hidden>
        <Badge Icon={notificationIcon} />
      </div> */}
      <StyledPopover
        id={id}
        open={openNotif}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorReference="anchorPosition"
        // anchorPosition={{ right: isOpen ? '15rem' : '4rem' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <NotificationDropdown
          loading={loading}
          notificationData={notificationData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          handleResolved={handleResolved}
          handleClose={handleClose}
          unresolvedNotifications={unresolvedNotifications}
        />
      </StyledPopover>
      {/* <div style={{ marginBottom: '0.2rem', color: 'white', textDecoration: 'none' }}>
      {isOpen ? 'Notifications' : ''}</div> */}
      <Toaster open={open} msg={message} status={status} handleClose={() => setOpen(!open)} />
    </div>
    )
  );
}
