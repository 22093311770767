import { Tooltip } from '@mui/material';
import React from 'react';

function CustomizedTooltip(props) {
  const {
    text = '', textNewLine = '', position, children, boxWidth = 'fit-content',
  } = props;
  return (
    <Tooltip
      title={(
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="h3Regular">{text}</span>
          <span className="h3Regular">{textNewLine}</span>
        </div>
)}
      placement={position || 'right'}
      arrow
      disableInteractive={false}
      componentsProps={{
        tooltip: {
          sx: {
            visibility: text === '' ? 'hidden' : '',
            bgcolor: '#15284B',
            '& .MuiTooltip-arrow': {
              color: '#15284B',
            },
            width: boxWidth,
          },
        },
        popper: {
          sx: {
            zIndex: `${110000}!important`,
          },
        },
      }}
    >
      {/* <Button>top-start</Button> */}
      {children}
    </Tooltip>
  );
}

export default CustomizedTooltip;
