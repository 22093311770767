import React from 'react';
import { makeStyles } from '@mui/styles';
import ButtonOutlined from '../Button/ButtonOutlined';
import NotificationDropdownContent from './NotificationDropdownContent';
import Notificationkeleton from '../Skeleton/Notificationkeleton';

export default function NotificationDropdown(props) {
  const {
    loading,
    notificationData,
    currentPage,
    setCurrentPage,
    totalPages,
    handleResolved,
    handleClose,
    unresolvedNotifications,
  } = props;
  const useStyles = makeStyles((theme) => ({
    container: {
      width: '20.125rem',
    },
    dropdownOption: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0.5rem',
      cursor: 'pointer',
      background: theme.palette.blueGrayMid,
      position: 'sticky',
      top: 0,
    },
    settingFont: {
      color: theme.palette.grayDark1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '0.5rem',
      textDecorationLine: 'underline',
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: '0.5rem',
    },
    loader: {
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      // height: '10vh',
      justifyContent: 'center',
    },
    notificationTextContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.7rem',
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.dropdownOption} aria-hidden>
        <div className={classes.notificationTextContainer}>
          <div className={`${classes.notificationText} ${'h3Regular'}`}>Notifications</div>
          <div className={`${classes.notificationText} ${'h3Regular'}`} style={{ color: '#616E84' }}>{unresolvedNotifications !== 0 && unresolvedNotifications}</div>
        </div>
      </div>
      {Object.keys(notificationData)?.length > 0
        && Object.keys(notificationData).map((key) => (
          notificationData[key].map((data) => (
            <NotificationDropdownContent
              data={data}
              handleResolved={handleResolved}
              handleClose={handleClose}
            />
          ))
        ))}
      {
        loading && (<Notificationkeleton />)
      }
      {totalPages > currentPage && !loading
        && (
          <div className={classes.button}>
            <ButtonOutlined text="View More" onClick={() => setCurrentPage(currentPage + 1)} />
          </div>
        )}
    </div>
  );
}
